/* tslint:disable */
/* eslint-disable */
/**
 * MBFS.AppServer.Ecc.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'email'?: string | null;
    /**
     * 
     * @type {Country}
     * @memberof Address
     */
    'country'?: Country | null;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {ApiResponseType}
     * @memberof ApiError
     */
    'status'?: ApiResponseType;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'developerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'userMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiError
     */
    'errorCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'moreInfo'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiResponseType = {
    Undefined: 'Undefined',
    Success: 'Success',
    Fail: 'Fail',
    Error: 'Error'
} as const;

export type ApiResponseType = typeof ApiResponseType[keyof typeof ApiResponseType];


/**
 * 
 * @export
 * @interface CommercialDetails
 */
export interface CommercialDetails {
    /**
     * 
     * @type {string}
     * @memberof CommercialDetails
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CommercialDetails
     */
    'companyName2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialDetails
     */
    'foundationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialDetails
     */
    'taxNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CommercialDetails
     */
    'commercialRegisterNumber'?: string | null;
    /**
     * 
     * @type {ContactPerson}
     * @memberof CommercialDetails
     */
    'ceo'?: ContactPerson | null;
    /**
     * 
     * @type {LegalForm}
     * @memberof CommercialDetails
     */
    'legalForm'?: LegalForm | null;
}
/**
 * 
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * 
     * @type {Salutations}
     * @memberof ContactPerson
     */
    'salutation'?: Salutations | null;
    /**
     * 
     * @type {Salutations}
     * @memberof ContactPerson
     */
    'title'?: Salutations | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    'lastName': string;
    /**
     * 
     * @type {Salutations}
     * @memberof ContactPerson
     */
    'titleGiven'?: Salutations | null;
    /**
     * 
     * @type {Salutations}
     * @memberof ContactPerson
     */
    'titleInternational'?: Salutations | null;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Country
     */
    'eu'?: boolean | null;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'newCreated'?: boolean;
    /**
     * 
     * @type {Rating}
     * @memberof Customer
     */
    'riskIndicator'?: Rating | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'partnerId'?: string | null;
    /**
     * 
     * @type {Salutations}
     * @memberof Customer
     */
    'title'?: Salutations | null;
    /**
     * 
     * @type {Salutations}
     * @memberof Customer
     */
    'salutation'?: Salutations | null;
    /**
     * 
     * @type {Salutations}
     * @memberof Customer
     */
    'titleGiven'?: Salutations | null;
    /**
     * 
     * @type {Salutations}
     * @memberof Customer
     */
    'titleInternational'?: Salutations | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name2'?: string | null;
    /**
     * 
     * @type {CustomerTypeEnum}
     * @memberof Customer
     */
    'customerType': CustomerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'iban'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'dekatoId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'ksvId'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    'address': Address;
    /**
     * 
     * @type {CommercialDetails}
     * @memberof Customer
     */
    'commercialCustomerDetails'?: CommercialDetails | null;
    /**
     * 
     * @type {PrivateDetails}
     * @memberof Customer
     */
    'privateCustomerDetails'?: PrivateDetails | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerTypeEnum = {
    UnknownEnum: 'UnknownEnum',
    PrivateEnum: 'PrivateEnum',
    CommercialEnum: 'CommercialEnum'
} as const;

export type CustomerTypeEnum = typeof CustomerTypeEnum[keyof typeof CustomerTypeEnum];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {ErrorResponseBody}
     * @memberof ErrorResponse
     */
    'error'?: ErrorResponseBody | null;
}
/**
 * 
 * @export
 * @interface ErrorResponseBody
 */
export interface ErrorResponseBody {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponseBody
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ErrorResponseBody
     */
    'code'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseBody
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseBody
     */
    'docUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface HealthResponse
 */
export interface HealthResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthResponse
     */
    'proxy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HealthResponse
     */
    'statuscode'?: number | null;
}
/**
 * 
 * @export
 * @interface LegalForm
 */
export interface LegalForm {
    /**
     * 
     * @type {number}
     * @memberof LegalForm
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LegalForm
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalForm
     */
    'cmsId'?: string | null;
}
/**
 * 
 * @export
 * @interface LookUpResponse
 */
export interface LookUpResponse {
    /**
     * 
     * @type {ResultEnum}
     * @memberof LookUpResponse
     */
    'result': ResultEnum;
    /**
     * 
     * @type {Customer}
     * @memberof LookUpResponse
     */
    'customer': Customer;
}


/**
 * 
 * @export
 * @interface PrivateDetails
 */
export interface PrivateDetails {
    /**
     * 
     * @type {string}
     * @memberof PrivateDetails
     */
    'birthdate': string;
}
/**
 * 
 * @export
 * @interface Rating
 */
export interface Rating {
    /**
     * 
     * @type {number}
     * @memberof Rating
     */
    'score'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Rating
     */
    'probabilityOfDefault'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResultEnum = {
    NewCustomerEnum: 'NewCustomerEnum',
    ExistingCustomerEnum: 'ExistingCustomerEnum'
} as const;

export type ResultEnum = typeof ResultEnum[keyof typeof ResultEnum];


/**
 * 
 * @export
 * @interface Salutation
 */
export interface Salutation {
    /**
     * 
     * @type {number}
     * @memberof Salutation
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Salutation
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface Salutations
 */
export interface Salutations {
    /**
     * 
     * @type {number}
     * @memberof Salutations
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Salutations
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface Title
 */
export interface Title {
    /**
     * 
     * @type {number}
     * @memberof Title
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Title
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface TitleGiven
 */
export interface TitleGiven {
    /**
     * 
     * @type {number}
     * @memberof TitleGiven
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TitleGiven
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TitleGiven
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface TitleInternational
 */
export interface TitleInternational {
    /**
     * 
     * @type {number}
     * @memberof TitleInternational
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TitleInternational
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TitleInternational
     */
    'text'?: string | null;
}

/**
 * ECCApiApi - axios parameter creator
 * @export
 */
export const ECCApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerLookup: async (apiVersion: string, uNKNOWNBASETYPE?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('customerLookup', 'apiVersion', apiVersion)
            const localVarPath = `/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uNKNOWNBASETYPE, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Indicates if the service is still up and running
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (apiVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('ping', 'apiVersion', apiVersion)
            const localVarPath = `/Health/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ECCApiApi - functional programming interface
 * @export
 */
export const ECCApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ECCApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerLookup(apiVersion: string, uNKNOWNBASETYPE?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerLookup(apiVersion, uNKNOWNBASETYPE, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Indicates if the service is still up and running
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(apiVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ECCApiApi - factory interface
 * @export
 */
export const ECCApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ECCApiApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerLookup(apiVersion: string, uNKNOWNBASETYPE?: any, options?: any): AxiosPromise<LookUpResponse> {
            return localVarFp.customerLookup(apiVersion, uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Indicates if the service is still up and running
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(apiVersion: string, options?: any): AxiosPromise<HealthResponse> {
            return localVarFp.ping(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ECCApiApi - object-oriented interface
 * @export
 * @class ECCApiApi
 * @extends {BaseAPI}
 */
export class ECCApiApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} apiVersion The requested API version
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ECCApiApi
     */
    public customerLookup(apiVersion: string, uNKNOWNBASETYPE?: any, options?: AxiosRequestConfig) {
        return ECCApiApiFp(this.configuration).customerLookup(apiVersion, uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Indicates if the service is still up and running
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ECCApiApi
     */
    public ping(apiVersion: string, options?: AxiosRequestConfig) {
        return ECCApiApiFp(this.configuration).ping(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MasterdataApiApi - axios parameter creator
 * @export
 */
export const MasterdataApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCountries: async (apiVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('getAllCountries', 'apiVersion', apiVersion)
            const localVarPath = `/Country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLegalFormsUnpaged: async (apiVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('getAllLegalFormsUnpaged', 'apiVersion', apiVersion)
            const localVarPath = `/LegalForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSalutations: async (apiVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('getAllSalutations', 'apiVersion', apiVersion)
            const localVarPath = `/Salutations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTitlesGivenUnpaged: async (apiVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('getAllTitlesGivenUnpaged', 'apiVersion', apiVersion)
            const localVarPath = `/TitleGiven`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTitlesInternationalUnpaged: async (apiVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('getAllTitlesInternationalUnpaged', 'apiVersion', apiVersion)
            const localVarPath = `/TitleInternational`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTitlesUnpaged: async (apiVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiVersion' is not null or undefined
            assertParamExists('getAllTitlesUnpaged', 'apiVersion', apiVersion)
            const localVarPath = `/Title`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterdataApiApi - functional programming interface
 * @export
 */
export const MasterdataApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterdataApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCountries(apiVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCountries(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLegalFormsUnpaged(apiVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LegalForm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLegalFormsUnpaged(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSalutations(apiVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Salutation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSalutations(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTitlesGivenUnpaged(apiVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TitleGiven>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTitlesGivenUnpaged(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTitlesInternationalUnpaged(apiVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TitleInternational>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTitlesInternationalUnpaged(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTitlesUnpaged(apiVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Title>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTitlesUnpaged(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MasterdataApiApi - factory interface
 * @export
 */
export const MasterdataApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterdataApiApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCountries(apiVersion: string, options?: any): AxiosPromise<Array<Country>> {
            return localVarFp.getAllCountries(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLegalFormsUnpaged(apiVersion: string, options?: any): AxiosPromise<Array<LegalForm>> {
            return localVarFp.getAllLegalFormsUnpaged(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSalutations(apiVersion: string, options?: any): AxiosPromise<Array<Salutation>> {
            return localVarFp.getAllSalutations(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTitlesGivenUnpaged(apiVersion: string, options?: any): AxiosPromise<Array<TitleGiven>> {
            return localVarFp.getAllTitlesGivenUnpaged(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTitlesInternationalUnpaged(apiVersion: string, options?: any): AxiosPromise<Array<TitleInternational>> {
            return localVarFp.getAllTitlesInternationalUnpaged(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apiVersion The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTitlesUnpaged(apiVersion: string, options?: any): AxiosPromise<Array<Title>> {
            return localVarFp.getAllTitlesUnpaged(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MasterdataApiApi - object-oriented interface
 * @export
 * @class MasterdataApiApi
 * @extends {BaseAPI}
 */
export class MasterdataApiApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterdataApiApi
     */
    public getAllCountries(apiVersion: string, options?: AxiosRequestConfig) {
        return MasterdataApiApiFp(this.configuration).getAllCountries(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterdataApiApi
     */
    public getAllLegalFormsUnpaged(apiVersion: string, options?: AxiosRequestConfig) {
        return MasterdataApiApiFp(this.configuration).getAllLegalFormsUnpaged(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterdataApiApi
     */
    public getAllSalutations(apiVersion: string, options?: AxiosRequestConfig) {
        return MasterdataApiApiFp(this.configuration).getAllSalutations(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterdataApiApi
     */
    public getAllTitlesGivenUnpaged(apiVersion: string, options?: AxiosRequestConfig) {
        return MasterdataApiApiFp(this.configuration).getAllTitlesGivenUnpaged(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterdataApiApi
     */
    public getAllTitlesInternationalUnpaged(apiVersion: string, options?: AxiosRequestConfig) {
        return MasterdataApiApiFp(this.configuration).getAllTitlesInternationalUnpaged(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterdataApiApi
     */
    public getAllTitlesUnpaged(apiVersion: string, options?: AxiosRequestConfig) {
        return MasterdataApiApiFp(this.configuration).getAllTitlesUnpaged(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}



