import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import React from "react";
import {Forms} from "./forms/Forms";
import {NotFound} from "./not-found/NotFound";
import {FormEdit} from "./forms/edit/FormEdit";
import {NewForm} from "./forms/new/NewForm";
import {FormView} from "./viewForms/FormView";
import {ViewForms} from "./viewForms/ViewForms";
import {MainLayout} from "./MainLayout";
import { EmbeddableFormView } from "./viewForms/EmbeddableFormView";
import { Test } from "./OCAPI/Test";
import ARCWidget from "./OCAPI/Widget/ARC_Widget";
import ECCWebClient from "./OCAPI/ECC_WebClient/ECC_WebClient";

export function RouteConfig() {
  return (
    <Routes>
      <Route path="ARC-Widget" element={<MainLayout />}>
        <Route index element={<Test />} />
      </Route>
      <Route path="ARC_Widget" element={<ARCWidget />}>
      </Route >
      <Route path="ECC-WebClient" element={<ECCWebClient />}>
      </Route >
      <Route path="/" element={<Navigate to="/ARC-Widget" replace={true} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
