import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import Container from "./Container";
import InputContainer from "./InputContainer";
export default function Widget(props: any) {

  const disclaimerArray: any = []

  return (
    <div className="ARC_Widget">
      <div className="IandO">
        <div className="inputTd">
          <Box className="InputBox">
            <InputContainer
              request={props.request}
              refresh={props.refresh}
              setRequest={props.setRequest}
              responseObject={props.responseObject}
            ></InputContainer>
          </Box>
        </div>
        {props.responseObject?.output?.containers?.filter((x: any) => x.id === "output").map((x: any) => {
          return (
            <div className="outputContainer" key={x.id}>
              <Container
                disclaimerArray={disclaimerArray}
                apiKey={props.apiKey}
                value={x}
                responseObject={props.responseObject}>
              </Container>
            </div>
          )
        })
        }
      </div>
      <table className="disclaimer">
        <tr>
          {props.responseObject?.output?.containers?.filter((x: any) => x.id === 'output')?.map((x: any) => {
            let i: any = 0
            return (
              <div>
                {
                  x.items.filter((y: any) => {
                    return y.disclaimer;
                  }).map((y: any) => {
                    let stars = "*";
                    for (let j: any = 0; j < i; j++) {
                      stars += "*"
                    }
                    i++
                    return <div>{stars + " " + y.disclaimer}</div>;
                  })
                }
              </div>
            )
          })}
        </tr>
      </table>
    </div>
  );
}