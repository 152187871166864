/* eslint-disable array-callback-return */
import InputCheckbox from "./InputCheckbox";
import InputComboBox from "./InputComboBox";
import InputNumber from "./InputNumber";
import OutputText from "./OutputText";
export default function ValuesContainer(props: any) {

  return (
    <div className="insuranceBody">
      <div className="InputContainer"
        id="demo-simple-select">
        {props.items?.map((x: any) => {
          switch (x.type) {
            case "oneOfMany": return (<InputComboBox request={props.request} refresh={props.refresh} setRequest={props.setRequest} key={x.id} x={x} />)
            case "number": return (<InputNumber request={props.request} refresh={props.refresh} setRequest={props.setRequest} key={x.id} x={x} />)
            case "boolean": return (<InputCheckbox request={props.request} refresh={props.refresh} setRequest={props.setRequest} key={x.id} x={x} />)
            case "outputText": return (<OutputText request={props.request} refresh={props.refresh} key={x.id} x={x}></OutputText>)
          }
        })}
      </div>
    </div>
  );
}
