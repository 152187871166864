import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

export default function InputNumber(props: any) {

  const handleChange = (event: any) => {

    let onlyNums = event.target.value.replace(/[^0-9,]/g, '');
    onlyNums = onlyNums.replace(/(,)(?=.*\1)/g, "")
    onlyNums = "€ " + onlyNums
    setValue(onlyNums)

  };

  const [currentRefresh, setCurrentRefresh] = useState(0)
  const [value, setValue] = useState(props.x.value.label)

  if (props.refresh != currentRefresh) {
    setValue(props.x.value.label)
    setCurrentRefresh(props.refresh)
    props.request.input.push({
      "id": (props.x.id),
      "value": (props.x.value.value + '')
    })
  }

  const onBlur = (event: any) => {
    let onlyNums = event.target.value.replace(/[^0-9,]/g, '');
    onlyNums = onlyNums.replace(',', '.')
    const inputValue = (Number(onlyNums))
    onlyNums = Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(inputValue)
    onlyNums = "€ " + onlyNums
    setValue(onlyNums)

    props.setRequest((request: any) => {
      console.log(request)
      const res = ({
        ...request
      }
      );

      if (!res.input)
        res.input = []

      const element = res.input.find((x: any) => x.id === props.x.id)

      if (element) { element.value = inputValue + '' }
      else {
        res.input.push({
          "id": (props.x.id),
          "value": (inputValue + '')
        })
      }
      return res
    }
    )
  }

  return (
    <div>
    <TextField
      onBlur={onBlur }
      fullWidth
      className="InputNumber"
      value={value}
      onChange={handleChange}
      label={props.x.label}
      InputLabelProps={{ className: "inputNumberHeader" }}
 /*     InputProps={{
        startAdornment:
          <InputAdornment
            position="start">{props.x.unitFormatted}</InputAdornment>
      }} */ >
      </TextField>
      <div className="downPaymentMessage">{props.x.message }</div>
    </div>
  );
}
