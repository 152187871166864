import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Postleitzahl(props: any) {
  const [postleitzahl, setPostleitzahl] = React.useState("");
  const [counter, setCounter] = React.useState(0);

  const handleChange = (e: { target: { value: string; }; }) => {
    const wordRes = (e.target.value.replace(/[^0-9]/gi, ''));

    setPostleitzahl(wordRes);
    props.setPostalCode(wordRes);
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setPostleitzahl(props.eccResponse.address.postalCode);
  //  } else {
  //    setCounter(1);
  //  }
  //}, [props.eccResponse]);

  if (props.clearTxt) {
    if (postleitzahl !== "") {
      setPostleitzahl("")
      props.setPostalCode("");
    }
    props.setClearTxt(false)
    return (
      <Box component="form">
        <TextField
          className="ECC-Input"
          label="Postleitzahl"
        />
      </Box>
    )
  } else {
    return (
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <TextField
          className="ECC-Input"
          id="standard-basic"
          label="Postleitzahl"
          variant="standard"
          value={postleitzahl}
          onChange={handleChange}
        />
      </Box>
    )
  }
}