import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Firmenname(props: any) {
  const [visible, setVisible] = React.useState(false);
  const [firmenname, setFirmenname] = React.useState("");
  const [counter, setCounter] = React.useState(0);

  const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setFirmenname(e.target.value);
    props.setCompanyName(e.target.value);
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setFirmenname("");
  //  } else {
  //    setCounter(1);
  //  }
    
  //}, [props.eccResponse]);

  if (props.customerType === 2) {
    if (!visible) {
      setVisible(true)
    }
  } else if (props.customerType === 1) {
    if (visible) {
      setVisible(false)
    }
  }

  if (props.clearTxt) {
    if (firmenname !== "") {
      setFirmenname("")
      props.setCompanyName("");
    }
    props.setClearTxt(false)
    return (
      <Box component="form">
        <TextField
          className="ECC-Input"
          label="Firmenname**"
        />
      </Box>
    )
  } else {
    return (
      <div>
      { visible && (
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <TextField
            className="ECC-Input"
            id="standard-basic"
            label="Firmenname**"
            variant="standard"
            value={firmenname}
            onChange={handleChange}
          />
        </Box>
      )}
      </div>
    )
  }
}