/* eslint-disable array-callback-return */
export default function ContainerItem(props: any) {

  let stars = ""
  if (props.value.disclaimer) {
    for (let i: any = 0; i <= props.disclaimerArray.length; i++) {
      stars += "*"
    }
    props.disclaimerArray.push(stars + props.value.disclaimer)

  }
  return (
    <div className={props.value?.highlight === true ? 'bold' : 'notBold'}>
      <h6 className={props.value?.highlight === true ? 'bold' : 'notBold'}>
        {props.value.label}{stars}
      </h6>
      {props.value?.value}
      <hr>
      </hr>
    </div>
  );
}
