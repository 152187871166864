import * as React from 'react';
import { Header } from "../../../common/header/Header";
import Anrede from "./Anrede";
import EMailAdresse from "./EMailAdresse";
import Familienname from "./Familienname";
import Geburtsdatum from "./Geburtsdatum";
import Hausnummer from "./Hausnummer";
import KSVId from "./KSVId";
import Land from "./Land";
import Ort from "./Ort";
import Postleitzahl from "./Postleitzahl";
import Reset from "./Reset";
import Senden from "./Senden";
import Strasse from "./Strasse";
import Telefonnummer from "./Telefonnummer";
import Vorname from "./Vorname";
import Response from "./Response";
import CustomerType from "./CustomerType";
import Gesellschaftsform from "./Gesellschaftsform";
import Firmenname from "./Firmenname";
import UidNummer from './UidNumber';

export default function ECC_WebClient(props: any) {
  const [clearTxt, setClearTxt] = React.useState(false)
  const [name, setName] = React.useState("");
  const [name2, setName2] = React.useState("");
  const [customerType, setCustomerType] = React.useState('1');
  const [salutation, setSalutation] = React.useState(0);
  const [birthdate, setBirthdate] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [houseNumber, setHouseNumber] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [ksvId, setKsvId] = React.useState("");
  const [eccResponse, setEccResponse] = React.useState({});
  const [uid, setUid] = React.useState("")
  const [companyName, setCompanyName] = React.useState("")
  const [gesellschaftsform, setGesellschaftsform] = React.useState("")
  const [commercialObj, setCommercialObj] = React.useState({
    "customerType": "CommercialEnum",
    "address": {
      "street": "Zelinkagasse",
      "houseNumber": "10",
      "city": "Wien",
      "postalCode": "1010",
      "phone": "00 01 01 01 01",
      "email": "tester@testen.de",
      "country": {
        "code": "at"
      }
    },
    "commercialCustomerDetails": {
      "companyName": "Bruno Brummer TESTFIRMA ITO",
      "taxNumber": "0",
      "legalForm": {
        "id": 378
      },
      "ceo": {
        "firstName": "",
        "lastName": "",
        "salutation": {
          "id": 9
        }
      }
    }
  })

  const [customerObj, setCustomerObj] = React.useState({
    "name": "Lukas",
    "name2": "",
    "customerType": "PrivateEnum",
    "salutation": {
      "id": 9
    },
    "titleGiven": {
      "id": 1
    },
    "titleInternational": {
      "id": 1
    },
    "title": {
      "id": 1
    },
    ksvId: "",
    "iban": "AT023500000001070671",
    "address": {
      "street": "Teststrasse",
      "houseNumber": "1",
      "city": "Wels",
      "postalCode": "4600",
      "phone": "00 01 01 01 01",
      "email": "tester@testen.de",
      "country": {
        "code": "at"
      }
    },
    "privateCustomerDetails": {
      "birthdate": "1979-07-30"
    },
  });

  const updateReq = ( salutation: any, name: string, name2: string, customerType: string, birthdate: string, street: string, houseNumber: string, city: string, postalCode: string, phone: string, email: string, countryCode: string, ksvId: string, companyName:string, uid : string, gesellschaftsform : any) => {
    setCommercialObj(prevState => (
      {
        ...prevState,
        address: {
          street: street,
          houseNumber: houseNumber,
          city: city,
          postalCode: postalCode,
          phone: phone,
          email: email,
          country: {
            code: countryCode,
          },
        },
        commercialCustomerDetails: {
          companyName: companyName,
          taxNumber: uid,
          legalForm: {
            id: gesellschaftsform,
          },
          ceo: {
            firstName: "CEO First Name",
            lastName: "CEO Last Name",
            salutation: {
              id: 9
            }
          }
        },
      }));
    
    setCustomerObj(prevState => (
      {
        ...prevState,
        address: {
          street: street,
          houseNumber: houseNumber,
          city: city,
          postalCode: postalCode,
          phone: phone,
          email: email,
          country: {
            code: countryCode,
          },
        },
        ksvId: ksvId,
        customerType: customerType,
        salutation: { id: salutation },
        name: name,
        name2: name2,
        privateCustomerDetails: { birthdate: birthdate },
      }));
  };

  const responseObj = eccResponse
  return (
    <div>
      <Header />
      <CustomerType
        className="EccCustomerType"
        clearTxt={clearTxt}
        setClearTxt={setClearTxt}
        setCustomerType={setCustomerType}
        eccResponse={eccResponse}
      />
      <div className="ECC-FlexBox">
        <div className="ECC-FlexColumn">
          <Gesellschaftsform
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            customerType={customerType}
            setGesellschaftsform={setGesellschaftsform}
            eccResponse={eccResponse}
          />
          <Firmenname
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            customerType={customerType}
            setCompanyName={setCompanyName}
            eccResponse={eccResponse}
          />
          <Anrede
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setSalutation={setSalutation}
            customerType={customerType}
            eccResponse={eccResponse}
          />
          <Vorname
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setName={setName}
            customerType={customerType}
            eccResponse={eccResponse}
          />
          <Familienname
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setName2={setName2}
            customerType={customerType}
            eccResponse={eccResponse}
          />
          <Geburtsdatum
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setBirthdate={setBirthdate}
            customerType={customerType}
            eccResponse={eccResponse}
          />
          <Telefonnummer
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setPhone={setPhone}
            eccResponse={eccResponse}
          />
          <EMailAdresse
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setEmail={setEmail}
            eccResponse={eccResponse}
          />
        </div>
        <div className="ECC-FlexColumn">
          <Strasse
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setStreet={setStreet}
            eccResponse={eccResponse}
          />
          <Hausnummer
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setHouseNumber={setHouseNumber}
            eccResponse={eccResponse}
          />
          <Postleitzahl
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setPostalCode={setPostalCode}
            eccResponse={eccResponse}
          />
          <Ort
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setCity={setCity}
            eccResponse={eccResponse}
          />
          <Land
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setCountryCode={setCountryCode}
            eccResponse={eccResponse}
          />
          <KSVId
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            setKsvId={setKsvId}
            customerType={customerType}
            eccResponse={eccResponse}
          />
          <UidNummer
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
            customerType={customerType}
            setUid={setUid}
            eccResponse={eccResponse}
          />
        </div>
      </div>
      <div className="ECC-FlexBox">
        <div className="ECC-FlexColumn">
          <Reset
            clearTxt={clearTxt}
            setClearTxt={setClearTxt}
          />
        </div>
        <div className="ECC-FlexColumn">
          <Senden
            commercialObj={commercialObj}
            setCommercialObj={setCommercialObj}
            customerType={customerType}
            customerObj={customerObj}
            setCustomerObj={setCustomerObj}
            eccResponse={eccResponse}
            setEccResponse={setEccResponse}
            updateReq={updateReq}
            salutation={salutation}
            name={name}
            name2={name2}
            birthdate={birthdate}
            phone={phone}
            email={email}
            street={street}
            houseNumber={houseNumber}
            postalCode={postalCode}
            city={city}
            countryCode={countryCode}
            ksvId={ksvId}
            gesellschaftsform={gesellschaftsform}
            uid={uid}
            companyName={companyName }
          />
        </div>
      </div>
      <div className="ECC-Flexbox">
        <Response
          responseObj={responseObj}
        />
      </div>
    </div>
  )
}