import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';

export default function CustomerType(props: any) {
  const [kunde, setKunde] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKunde(event.target.checked);
    props.setClearTxt(true);
    props.setCustomerType(event.target.checked ? 1 : 2);
  };

  return (
    <Box display="flex">
      <div className="CustomerType">
        <Box display="flex" alignItems="center">
          <h3 style={{ fontFamily: 'sans-serif', marginRight: '10px', fontWeight: kunde ? 'normal' : 'bold' }}>Gewerblich</h3>
          <FormControlLabel
            className="ECC-Switch"
            control={<Switch checked={kunde} onChange={handleChange} name="kunde-switch" />}
            label=""
          />
        </Box>
      </div>
      <div>
        <h3 style={{ fontFamily: 'sans-serif', marginLeft: '-15px', fontWeight: kunde ? 'bold' : 'normal' }}>Privat</h3>
      </div>
    </Box>
  );
}
