import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

export default function Application(props: any) {
  let key = props.application?.key
  if (props.ocapiEnvironment?.application?.indexOf(props.application) < 0) {
    if (props.ocapiEnvironment?.application && props.ocapiEnvironment?.application.length !== 0) {
      props.setApplication(props.ocapiEnvironment?.application[0])
      key = props.ocapiEnvironment?.application[0].key
    }
  }
  const handleChange = (event: SelectChangeEvent) => {
    const i = props.ocapiEnvironment?.application?.findIndex((x: any) => x.key === event.target.value)
    props.setApplication(props.ocapiEnvironment?.application[i]);
  };
  return (
    <div>
      <FormControl className="sideContent" variant="filled">
        <InputLabel htmlFor="grouped-native-select">Application</InputLabel>
        <Select
          disabled={props.customEnvironment === props.ocapiEnvironment}
          value={key ?? ""}
          onChange={handleChange}
        >
          {props.ocapiEnvironment?.application?.map((x: any) => {
            return <MenuItem value={x.key} key={x.name}>{x.name}</MenuItem>
          })
          }
        </Select>
      </FormControl>
    </div>
  );
}
