import ChildrenContainer from "./ChildrenContainer";
import ValuesContainer from "./ValuesContainer";


export default function InputContainer(props: any) {

  return (
    <div className="InputContainer">
      <h2 className="inputHeader">{props.responseObject?.input?.label}</h2>
      <ValuesContainer request={props.request} refresh={props.refresh} input={props.responseObject?.input}  setRequest={props.setRequest} items={props.responseObject?.input?.items}></ValuesContainer>
      <ChildrenContainer request={props.request} refresh={props.refresh} setRequest={props.setRequest} responseObject={props.responseObject}></ChildrenContainer>
    </div>
  );
}
