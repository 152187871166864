import { InputAdornment, TextField } from "@mui/material";

export default function OutputText(props: any) {



  return (
    <div>
      <TextField
        className="outputText"
        fullWidth
        label={props.x.label}
        InputLabelProps={{ className: "outputTextHeader" }}
        value={props.x.value.label}>
      </TextField>
      <div className="downPaymentMessage">{props.x.message}</div>
    </div >
  );
}