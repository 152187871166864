import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

export default function InputComboBox(props: any) {


  const handleChange = (event: any) => {
    setValue(event.target.value)
    props.setRequest((request: any) => {
      console.log(request)
      const res = ({
        ...request
      }
      )
      if (!res.input)
        res.input = []

      const element = res.input.find((x: any) => x.id === props.x.id)

      if (element) { element.value = event.target.value }
      else {
        res.input.push({
          "id": (props.x.id),
          "value": (event.target.value)
        })
      }
      return res
    }
    )
  };

  const [currentRefresh, setCurrentRefresh] = useState(0)
  const [value, setValue] = useState(props.x.value.value)

  if (props.refresh !== currentRefresh) {
    setValue(props.x.value.value)
    setCurrentRefresh(props.refresh)
    props.request.input.push({
      "id": (props.x.id),
      "value": (props.x.value.value + '')
    })
  }


  return (
    <div>
      <FormControl
        className="InputComboBox" fullWidth>
        <InputLabel className="inputComboboxHeader">
          {props.x.label}
        </InputLabel>
        <Select
          onChange={handleChange}
          label={props.x.label}
          value={value}>
          {props.x.values?.map((y: any) => {
            return (
              <MenuItem
                value={y.value}>
                {y.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <div className="downPaymentMessage">{props.x.message}</div>
    </div>
  );
}
