import ChildContainer from "./ChildContainer";

export default function ChildrenContainer(props: any) {
  return (
    <div className="test7">
      {props.responseObject?.input?.childContainers?.map((x: any) => {
        return (<ChildContainer request={props.request} refresh={props.refresh} setRequest={props.setRequest} child={x}></ChildContainer>)
      }
      )}
    </div>
  )
}