import "../themes/app.scss";
import { BrowserRouter } from "react-router-dom";
import { Header } from "../common/header/Header";
import { Box } from "@mui/material";
import { RouteConfig } from "./RouteConfig";

function App(props: any) {
  return (
    <BrowserRouter>
      <RouteConfig />
    </BrowserRouter>
  );
}

export default App;
