import React, { useState } from "react";
import APIKey from "./APIKey";
import Application from "./Application";
import EditRequest from "./EditRequest";
import Language from "./Language";
import OCAPIEnvironment from "./OCAPI Environment";
import VehicleSelect from "./VehicleSelect";

export function SideContent(props: any) {

  const [customEnvironment] = React.useState<any>(
    {
      "name": "Custom",
      "address": "",
      "application": [
        {
          "key": "",
          "name": "",
          "token": "",
          "label": "Other"
        }
      ]
    }
  )
  return (
    <div
      className="sideArea">
      <VehicleSelect
        vehicle={props.vehicle}
        setVehicle={props.setVehicle}
        setRequest={props.setRequest}
        config={props.config}
      >
      </VehicleSelect>
      <EditRequest
        request={props.request}
        setRequest={props.setRequest}
      ></EditRequest>
      <Language
        className="sideContent"
        language={props.language}
        setLanguage={props.setLanguage}
        config={props.config}
      ></Language>
      <Application
        customEnvironment={customEnvironment}
        className="sideContent"
        application={props.application}
        setApplication={props.setApplication}
        ocapiEnvironment={props.ocapiEnvironment}
      ></Application>
      <APIKey
        application={props.application}>
      </APIKey>
      <OCAPIEnvironment
        setRefresh={props.setRefresh}
        setCustomToken={(token: string) => { customEnvironment.application[0].token = token; }}
        setCustomAddress={(address: string) => { customEnvironment.address = address; }}
        customEnvironment={customEnvironment}
        className="sideContent"
        ocapiEnvironment={props.ocapiEnvironment}
        setOcapiEnvironment={props.setOcapiEnvironment}
        config={props.config}
      ></OCAPIEnvironment>
    </div>
  );
}
