import { Checkbox } from "@mui/material";
import React, { useState } from "react";

export default function InputCheckbox(props: any) {

  const [currentRefresh, setCurrentRefresh] = useState(0)
  const [checked, setChecked] = useState(props.x.value.selected)

  if (props.refresh != currentRefresh) {
    setChecked(props.x.value.selected)
    setCurrentRefresh(props.refresh)
    props.request.input.push({
      "id": (props.x.id),
      "value": (props.x.value.selected + '')
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)

    props.setRequest((request: any) => {
      console.log(request)
      const res = ({
        ...request
      }
      );

      if (!res.input)
        res.input = []

      const element = res.input.find((x: any) => x.id === props.x.id)

      if (element) { element.value = event.target.checked + '' }
      else {
        res.input.push({
          "id": (props.x.id),
          "value": (event.target.checked + '')
        })
      }
      return res
    }
    )
  };

  return (
    <table className="test5">
      <tr>
        <td>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }} />
        </td>
        <td>
          {props.x.label}
        </td>
      </tr>
      <tr>
        <td></td>
        <td className="insuranceInfo">
          {props.x.info}
        </td>
      </tr>
    </table>
  );
}