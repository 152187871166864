import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function Reset(props: any) {

  const clearTxt = () => {
    props.setClearTxt(true)
  }

  return (
    <div>
      <Stack
        spacing={2} className="ECC-Button"
        direction="row">
        <Button
          onClick={clearTxt}
          variant="contained"
          disableElevation
        >Zurücksetzten</Button>
      </Stack>
    </div>
  );
}
