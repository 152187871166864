import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function Anrede(props: any) {
  const [visible, setVisible] = React.useState(true);
  const [anrede, setAnrede] = React.useState('');
  const [counter, setCounter] = React.useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setAnrede(event.target.value);
    props.setSalutation(event.target.value)
  };

  /*
  React.useEffect(() => {
    if (counter > 0) {
      setAnrede(props.eccResponse.salutation.id);
    } else {
      setCounter(1);
    }
   
  }, [props.eccResponse]);*/

  if (props.customerType === 1) {
    if (!visible) {
      setVisible(true)
    }
  } else if (props.customerType === 2) {
    if (visible) {
      setVisible(false)
    }
  }

  if (props.clearTxt) {
    if (anrede !== "") {
      setAnrede("")
      props.setSalutation("")
    }
    props.setClearTxt(false)
    return (
      <FormControl className="ECC-Select" variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Anrede*</InputLabel>
        <Select label="Anrede">
        </Select>
      </FormControl>
    )
  } else {
    return (
      <div>
        {visible && (
          <FormControl className="ECC-Select" variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">Anrede*</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={anrede}
              onChange={handleChange}
              label="Anrede"
            >
              <MenuItem value={9}>Herr</MenuItem>
              <MenuItem value={10}>Frau</MenuItem>
              <MenuItem value={11}>Divers</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
    )
  }
}