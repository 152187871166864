import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Geburtsdatum(props: any) {
  const [visible, setVisible] = React.useState(true);
  const [geburtsdatum, setGeburtsdatum] = React.useState("");
  const [counter, setCounter] = React.useState(0);

  const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setGeburtsdatum(e.target.value);
    props.setBirthdate(e.target.value);
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setGeburtsdatum(props.eccResponse.privateCustomerDetails.birthdate);
  //  } else {
  //    setCounter(1);
  //  }
    
  //}, [props.eccResponse]);

  if (props.customerType === 1) {
    if (!visible) {
      setVisible(true)
    }
  } else if (props.customerType === 2) {
    if (visible) {
      setVisible(false)
    }
  }

  if (props.clearTxt) {
    if (geburtsdatum !== "") {
      setGeburtsdatum("")
      props.setBirthdate("");
    }
    props.setClearTxt(false)
    return (
      <Box component="form">
        <TextField
          className="ECC-Input"
          label="Geburtsdatum"
          type="date"
        />
      </Box>
    )
  } else {
    return (
      <div>
        {visible && (
          <Box
            component="form"
            noValidate
            autoComplete="off"
          >
            <TextField
              className="ECC-Input"
              id="standard-basic"
              label="Geburtsdatum"
              variant="standard"
              value={geburtsdatum}
              onChange={handleChange}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
        )}
      </div>
    )
  }
}