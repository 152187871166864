import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Grid, IconButton, Snackbar } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 820,
  height: 550,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function syntaxHighlight(json: any) {
  if (!json) return ""; //no JSON from response

  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");

  json = json.replace(/\n */g,
    function (match: any) {
      var res = "<br>";
      for (var i = 1; i < match.length; i++) {
        res = res + "&nbsp;";
      }
      return res;
    });

  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match: any) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
          match = match.replace(/&nbsp;/g, " ")
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

function validJson(jsonString: string): boolean {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

function saveRequestText(text: string, props: any) : boolean {
  const jsonText = text.replaceAll(/<[^>]*>/g, "").replaceAll("&nbsp;", "");
  if (validJson(jsonText)) {
    props.setRequest(JSON.parse(jsonText));
    return true;
  } else {
    return false;
  }
}
export default function EditRequest(props: any) {
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSave = () => {
    const requestField = document.querySelector('.requestField');
    if (requestField) {
      const requestText = requestField.innerHTML;
      if (saveRequestText(requestText, props)) {
        setOpenAlert(false);
        handleClose();
      } else {
        setOpenAlert(true);
      }
    }
  };


  return (
    <div>
      <Button
        disableElevation
        variant="contained"
        aria-label="Disabled elevation buttons"
        className="sideContent"
        onClick={handleOpen}
      >
        Edit Request
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            className="copyButton"
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(props.request, undefined, 4)
              );
            }}
          >
            <Grid item xs={8}>
              <ContentCopyIcon />
            </Grid>
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Request
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <table>
              <tr>
                <Box
                  contentEditable={true}
                  className="requestField"
                  dangerouslySetInnerHTML={{
                    __html: syntaxHighlight(
                      JSON.stringify(props.request, undefined, 4)
                    ),
                  }}
                />
              </tr>
              <tr>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} className="savebtn">
                  Save
                </Button>
              </tr>
            </table>
          </Typography>
        </Box>
      </Modal>

      {/* Alert for invalid JSON */}
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity="error">
          Invalid Request!
        </Alert>
      </Snackbar>
    </div>
  );
}