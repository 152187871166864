import axios, {AxiosError} from "axios";
import {pushNotificationHandler} from "../common/PushNotification";
import {AdminApi, ClientApi} from "../generated/clients";
import {BASE_PATH} from "../generated/clients/ECC/base";
import { LoadingIndicatorAdapter } from "../views/LoadingIndicator";
import { ECCApiApi, Configuration } from "../generated/clients/ECC/"

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNQi1SZW50IiwiaWF0IjoxNjkzOTIxNjU4LCJleHAiOjE3MjU1NDQwNTh9.5kIFowOYP35R4wviE4zDm5qRy9kLuy-VSCdqaZRFzhI"
  },
  validateStatus: (status) => status < 400
});

const loadingIndicatorAdapter = new LoadingIndicatorAdapter();
axiosInstance.interceptors.request.use((request) => {
  loadingIndicatorAdapter.show();

  return request;
});
axiosInstance.interceptors.response.use(
  (response) => {
    loadingIndicatorAdapter.hide();

    return response;
  },
  (error: AxiosError) => {
    loadingIndicatorAdapter.hide();

    const data = error.response?.data;

    return error;
  }
);

export async function initAxios() {
  let apiUrl = await getApiUrl();

  if (apiUrl.endsWith("/")) apiUrl = apiUrl.slice(0, -1);
  initClients(
    new Configuration({
      basePath: apiUrl
    })
  );
}

async function getApiUrl(): Promise<string> {
  /* if (process.env.NODE_ENV === "development") {
    const envVarKey = "REACT_APP_SSP_API_URL";
    let serviceUrlEnvVar = process.env[envVarKey];
    if (serviceUrlEnvVar) return serviceUrlEnvVar;
  }

  const portEnvVar = process.env.REACT_APP_PORT;
  if (portEnvVar) return `http://localhost:${process.env.REACT_APP_PORT}`;
  */
  const serviceDiscoveryUrl = new URL(window.location.origin);
  serviceDiscoveryUrl.pathname = "ECC-api-url.txt";
  try {
    const response = await axios.get(serviceDiscoveryUrl.toString());
    const apiLocation = response.data;

    if (apiLocation) return apiLocation;
  } catch (err) {
    console.warn(err);
  }

  return BASE_PATH;
}

let AdminApiClient: AdminApi;
let ClientApiClient: ClientApi;
let ECCApiClient: ECCApiApi;

function initClients(configuration: Configuration) {
  AdminApiClient = new AdminApi(configuration, undefined, axiosInstance);
  ClientApiClient = new ClientApi(configuration, undefined, axiosInstance);
  ECCApiClient = new ECCApiApi(configuration, undefined, axiosInstance);
}

export {AdminApiClient, ClientApiClient, ECCApiClient};
