import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function Gesellschaftsform(props: any) {
  const [visible, setVisible] = React.useState(false);
  const [gesellschaftsform, setGesellschaftsform] = React.useState('');
  const [counter, setCounter] = React.useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setGesellschaftsform(event.target.value);
    props.setGesellschaftsform(event.target.value);
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setGesellschaftsform("");
  //  } else {
  //    setCounter(1);
  //  }
    
  //}, [props.eccResponse]);

  if (props.customerType === 2) {
    if (!visible) {
      setVisible(true)
    }
  } else if (props.customerType === 1) {
    if (visible) {
      setVisible(false)
    }
  }

  if (props.clearTxt) {
    if (gesellschaftsform !== "") {
      setGesellschaftsform("")
      props.setGesellschaftsform("");
    }
    props.setClearTxt(false)
    return (
      <FormControl className="ECC-Select" variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Gesellschaftsform*</InputLabel>
        <Select label="Gesellschaftsform">
        </Select>
      </FormControl>
    )
  } else {
    return (
      <div>
      { visible && (
        <FormControl className="ECC-Select" variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">Gesellschaftsform*</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={gesellschaftsform}
            onChange={handleChange}
            label="Gesellschaftsform"
            >
              <MenuItem value={375}>Aktiengesellschaft</MenuItem>
              <MenuItem value={376}>Aktiengesellschaft & Co KG</MenuItem>
              <MenuItem value={377}>Aktiengesellschaft & Co OHG</MenuItem>
              <MenuItem value={378}>Ges. m.b.H</MenuItem>
              <MenuItem value={379}>Ges. m.b.H. & Co KG</MenuItem>
              <MenuItem value={380}>Ges. m.b.H. & Co. OHG</MenuItem>
              <MenuItem value={381}>Gesellschaft bürgerlichen Rechts</MenuItem>
              <MenuItem value={382}>Kommanditgesellschaft</MenuItem>
              <MenuItem value={383}>Kommanditerwerbsgesellschaft</MenuItem>
              <MenuItem value={384}>Offene Handelsgesellschaft</MenuItem>
              <MenuItem value={385}>Offene Erwerbsgesellschaft</MenuItem>
              <MenuItem value={386}>Offene Gesellschaft</MenuItem>
              <MenuItem value={387}>Einzelunternehmen</MenuItem>
              <MenuItem value={388}>Bund/Länder/Gemeinden</MenuItem>
              <MenuItem value={389}>Genossenschaft</MenuItem>
              <MenuItem value={390}>Stiftung</MenuItem>
          </Select>
        </FormControl>
        )}
      </div>
    )
  }
}