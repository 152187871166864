import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select, SelectChangeEvent } from '@mui/material';

export default function Language(props: any) {

  let key = props.language?.name
  if (props.config.languages?.indexOf(props.language) < 0) {
    if (props.config?.languages && props.config?.languages.length !== 0) {
      props.setLanguage(props.config.languages[0])
      key = props.config.languages[0].name
    }
  }
  const handleChange = (event: SelectChangeEvent) => {
    const i = props.config.ocapiEnvironment?.findIndex((x: any) => x.name === event.target.value)
    props.setLanguage(props.config.ocapiEnvironment[i]);
  };
  return (
    <div>
      <FormControl className="sideContent" variant="filled">
        <InputLabel htmlFor="grouped-native-select">Language</InputLabel>
        <Select
          defaultValue={props.defaultLanguage }
          value={props.language}
          onChange={handleChange}
        >
          {props.config?.languages?.map((x: any) => {
            return <MenuItem value={x} key={x.abbreviation}>{x.language}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  );
}
