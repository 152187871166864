import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Strasse(props: any) {
  const [strasse, setStrasse] = React.useState("");
  const [counter, setCounter] = React.useState(0);

  const handleChange = (e: { target: { value: string; }; }) => {
    const wordRes = (e.target.value.replace(/[^a-zA-Z0-9äöüßÄÖÜẞ -]+$/g, ''));

    setStrasse(wordRes);
    props.setStreet(wordRes);
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setStrasse(props.eccResponse.address.street);
  //  } else {
  //    setCounter(1);
  //  }
    
  //}, [props.eccResponse]);

  if (props.clearTxt) {
    if (strasse !== "") {
      setStrasse("")
      props.setStreet("");
    }
    props.setClearTxt(false)
    return (
      <Box component="form">
        <TextField
          className="ECC-Input"
          label="Stra&#223;e"
        />
      </Box>
    )
  } else {
    return (
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <TextField
          className="ECC-Input"
          id="standard-basic"
          label="Stra&#223;e"
          variant="standard"
          value={strasse}
          onChange={handleChange}
        />
      </Box>
    )
  }
}