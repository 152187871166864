/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, IconButton } from "@mui/material";
import axios from "axios";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Codebox from "../Codebox";
import Header from "./Header";
import Widget from "./Widget";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ARCWidget() {

  const tag = useRef(null);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    // @ts-ignore
    if (tag && tag.current && tag.current.clientHeight && tag.current.clientHeight !== height) {
      // @ts-ignore
      const newHeight: number = tag.current.clientHeight;

      let current = window.parent;
      while (current !== current.parent)
        current = current.parent;

      current.postMessage({ type: "widgetHeightResize", payload: { height: newHeight } }, '*');

      setHeight(newHeight);
    }
  });

  useEffect(() => {
    window.addEventListener("message", (e: MessageEvent) => {
      if (e?.data?.type === "ocapiData") {
        console.log(e?.data)
        const data = e.data.payload;
        if (data !== requestString) {
          setRequestString(data)
          try {
            setRequest(JSON.parse(data ?? ""))
          }
          catch (ex) {
            setRequest({})
          }
        }
      }
    });

    let current = window.parent;
    while (current !== current.parent)
      current = current.parent;

    current.postMessage({ type: "doneLoading", payload: {  } }, '*');

  }, []);
  const [showPayload, setShowPayload] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [refresh, setRefresh] = useState(0)
  const [address, setAddress] = useState<string | null>("");
  const [apiKey, setApiKey] = useState<string | null>("");
  const [token, setToken] = useState<string | null>("");
  const [requestString, setRequestString] = useState<string | null>("");
  const [request, setRequest] = useState<any>({});
  const [apiResponse, setApiResponse] = useState('');
  const [searchParams] = useSearchParams();
  const debugOptions = JSON.parse((searchParams.get("debug") ?? "false").toLowerCase())
  if (searchParams.get("URL") !== address)
    setAddress(searchParams.get("URL"))

  if (searchParams.get("ApiKey") !== apiKey)
    setApiKey(searchParams.get("ApiKey"))

  if (searchParams.get("Token") !== token)
    setToken(searchParams.get("Token"))

  useEffect(() => {
    const param: any = {
      headers: {
        "Content-Type": "application/json",
      }
    }
    if (apiKey) { param.headers["x-api-key"] = apiKey; }
    else { param.headers.authorization = token }
    const axiosInstance = axios.create(param);
    axiosInstance.post(address ?? "",
      request,
    ).then((res) => {
      console.log(res);
      request.input = []
      request.opaque = res.data.opaque
      setApiResponse(JSON.stringify(res.data));
      setRefresh(x => x + 1);
    })
  }, [request, address, apiKey, token])
  const responseObject = apiResponse ? JSON.parse(apiResponse) : null
  return (
    <table ref={ tag } className="ARC_Widget">
      <tr className="Header">
        <Header responseObject={responseObject}></Header>
      </tr>
      <tr>
        <td colSpan={2}>
          <Widget
            refresh={refresh}
            responseObject={responseObject}
            setRequest={setRequest}
            request={request}
          ></Widget>
        </td>
      </tr>
      {debugOptions &&
        <tr>
          <td className="codeBoxData">
            <IconButton
              className="copyButton"
              onClick={() => { navigator.clipboard.writeText((JSON.stringify(request, undefined, 4))) }}>
              <Grid item xs={8}>
                <ContentCopyIcon />
              </Grid>
            </IconButton>
            <Button variant="text" onClick={() => setShowPayload(prev => !prev)}>Request payload</Button>
            {showPayload && <Codebox
              json={request}>
            </Codebox>}
          </td>
          <td className="codeBoxData">
            <IconButton
              className="copyButton"
              onClick={() => { navigator.clipboard.writeText((JSON.stringify(responseObject, undefined, 4))) }}>
              <Grid item xs={8}>
                <ContentCopyIcon />
              </Grid>
            </IconButton >
            <Button variant="text" onClick={() => setShowResponse(prev => !prev)}>Raw response</Button>
            {showResponse && <Codebox
              json={responseObject}>
            </Codebox>}
          </td>
        </tr>
      }
    </table>
  );
}