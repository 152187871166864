
export default function APIKey(props: any) {
  return (
    < div className="APIKeyHeader" >
      <div className="APIKeyHeader">
        API-Key:
      </div>
      <div className="APIKey">
        {props?.application?.key}
      </div>
    </div >
  );
}