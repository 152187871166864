import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Familienname(props: any) {
  const [visible, setVisible] = React.useState(true);
  const [familienname, setFamilienname] = React.useState("");
  const [counter, setCounter] = React.useState(0);

  const handleChange = (e: { target: { value: string; }; }) => {
    const wordRes = (e.target.value.replace(/[^a-zA-Z0-9äöüßÄÖÜẞ -]+$/g, ''));

    setFamilienname(wordRes);
    props.setName2(wordRes)
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setFamilienname(props.eccResponse.name2);
  //  } else {
  //    setCounter(1);
  //  }
    
  //}, [props.eccResponse]);

  if (props.customerType === 1) {
    if (!visible) {
      setVisible(true)
    }
  } else if (props.customerType === 2) {
    if (visible) {
      setVisible(false)
    }
  }

  if (props.clearTxt) {
    if (familienname !== "") {
      setFamilienname("")
      props.setName2("")
    }
    props.setClearTxt(false)
    return (
      <Box component="form">
        <TextField
          className="ECC-Input"
          label="Familienname"
        />
      </Box>
    )
  } else {
    return (
      <div>
        {visible && (
          <Box
            component="form"
            noValidate
            autoComplete="off"
          >
            <TextField
              className="ECC-Input"
              id="standard-basic"
              label="Familienname"
              variant="standard"
              value={familienname}
              onChange={handleChange}
            />
          </Box>
        )}
      </div>
    )
  }

}
