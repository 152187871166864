import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function Land(props: any) {
  const [land, setLand] = React.useState('');
  const [counter, setCounter] = React.useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setLand(event.target.value);
    props.setCountryCode(event.target.value);
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setLand(props.eccResponse.address.country.code);
  //  } else {
  //    setCounter(1);
  //  }
  //}, [props.eccResponse]);

  if (props.clearTxt) {
    if (land !== "") {
      setLand("")
      props.setCountryCode("");
    }
    props.setClearTxt(false)
    return (
      <FormControl className="ECC-Select" variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Land</InputLabel>
        <Select label="Land">
        </Select>
      </FormControl>
    )
  } else {
    return (
      <FormControl className="ECC-Select" variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Land</InputLabel>
        <Select
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={land}
          onChange={handleChange}
          label="Land"
        >
          <MenuItem value={"AT"}>Österreich</MenuItem>
          <MenuItem value={"DE"}>Deutschland</MenuItem>
          <MenuItem value={"CH"}>Schweiz</MenuItem>
          <MenuItem value={"FR"}>Frankreich</MenuItem>
          <MenuItem value={"HU"}>Ungarn</MenuItem>
          <MenuItem value={"JA"}>Jamaica</MenuItem>
        </Select>
      </FormControl>
    )
  }

}