import { Alert, Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { ECCApiClient } from "../../../rest/RestHttpClient"

export default function Senden(props: any) {
  const [counter, setCounter] = React.useState(0);
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  }

  const handleRequest = () => {
    if (props.customerType === 1) {
      ECCApiClient.customerLookup("1.0", props.customerObj)
        .then((res) => {
          props.setEccResponse(res.data.customer);
          console.log(res.data.customer);
          setOpenAlert(false);
        })
        .catch((error) => {
          setOpenAlert(true);
          props.setEccResponse({});
        });
    } else if (props.customerType === 2){
      ECCApiClient.customerLookup("1.0", props.commercialObj)
        .then((res) => {
          props.setEccResponse(res.data.customer);
          console.log(res.data.customer);
          setOpenAlert(false);
        })
        .catch((error) => {
          setOpenAlert(true);
          props.setEccResponse({});
        });
    } else {
      ECCApiClient.customerLookup("1.0", props.customerObj)
        .then((res) => {
          props.setEccResponse(res.data.customer);
          console.log(res.data.customer);
          setOpenAlert(false);
        })
        .catch((error) => {
          setOpenAlert(true);
          props.setEccResponse({});
        });
    }
  };

  const handleSend = () => {
    props.updateReq(props.salutation, props.name, props.name2, props.customerType, props.birthdate, props.street, props.houseNumber, props.city, props.postalCode, props.phone, props.email, props.countryCode, props.ksvId, props.companyName, props.uid, props.gesellschaftsform);
  }

  React.useEffect(() => {
    if (counter > 0) {
      handleRequest();
    } else {
      setCounter(1);
    }
  }, [props.customerObj]);

  return (
    <div>
      <Stack
        spacing={2} className="ECC-Button"
        direction="row">
        <Button
          onClick={handleSend}
          variant="contained"
          disableElevation
        >Senden</Button>
      </Stack>

      {/* Customer not found Alert */}
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity="warning">
          Customer not found!
        </Alert>
      </Snackbar>
    </div>
  );
}