import "../../../App.css";

function syntaxHighlight(json: any) {
  if (!json) return ""; //no JSON from response

  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");

  json = json.replace(/\n */g,
    function (match: any) {
      var res = "<br>";
      for (var i = 1; i < match.length; i++) {
        res = res + "&nbsp;";
      }
      return res;
    });

  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match: any) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
          match = match.replace(/&nbsp;/g, " ")
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

export default function Response(props: any) {
  return (
    <td>
      <div
        className="codebox"
        dangerouslySetInnerHTML={{
          __html: syntaxHighlight(JSON.stringify(props.responseObj, undefined, 4))
        }} />
    </td>
  );
}
