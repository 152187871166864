import { Button } from "@mui/material";
import ContainerItem from "./ContainerItem";

export default function Container(props: any) {
  if (props.responseObject?.output?.links !== undefined) {
    return (
      <div>
        <h3
          className="outputHeader">
          {props.value?.label}
        </h3>
        {props.value?.items.map((x: any) => {
          return (
            <ContainerItem
              disclaimerArray={props.disclaimerArray }
              key={x.id}
              value={x}>
            </ContainerItem>)
        })}
        {props.responseObject?.output?.links.map((y: any) => {
          return (
            <div className="test55">
              <Button
                variant="contained"
                href={y.url}
                target="_blank">
                {y.label}
              </Button>
            </div>
          )
        })}
      </div>
    );
  }
  else {
    return (
      <div>
        <h3
          className="outputHeader">
          {props.value?.label}
        </h3>
        {props.value?.items.map((x: any) => {
          return (
            <ContainerItem
              disclaimerArray={props.disclaimerArray}
              key={x.id}
              value={x}>
            </ContainerItem>)
        })}
      </div>
    )
  }
}
