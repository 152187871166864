import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Box, Button, Select, SelectChangeEvent } from "@mui/material";
import { MenuItem } from '@mui/material';


export default function OCAPIEnvironment(props: any) {

  let key = props.ocapiEnvironment?.name
  if (props.config.ocapiEnvironment?.indexOf(props.vehicle) < 0) {
    /*if (props.config?.ocapiEnvironment && props.config?.ocapiEnvironment.length !== 0) {
      props.setOcapiEnvironment(props.config.ocapiEnvironment[0])
      key = props.config.ocapiEnvironment[0].name
    }*/
  }
  const handleChange = (event: SelectChangeEvent) => {
    const i = props.config.ocapiEnvironment?.findIndex((x: any) => x.name === event.target.value)
    if (i < 0)
      props.setOcapiEnvironment(props.customEnvironment);
    else
      props.setOcapiEnvironment(props.config.ocapiEnvironment[i]);
  };


  const handleConfirm = () => {
    const textAreaAddress = document.querySelector('.sideContentTextArea');
    if (textAreaAddress) {
      const newAddress = textAreaAddress.textContent;
      props.setCustomAddress(newAddress);
    }
    const textAreaToken = document.querySelector('#myTokenArea');
    if (textAreaToken) {
      const newToken = textAreaToken.textContent;
      props.setCustomToken(newToken);
    }
    props.setRefresh((x: any) => {

      return x + 1;
    })
  };
  return (
    <div>
      <div>
        <FormControl className="sideContent" variant="filled">
          <InputLabel htmlFor="grouped-native-select">OCAPI Environment</InputLabel>
          <Select
            value={key ?? ""}
            onChange={handleChange}
          >
            {props.config.ocapiEnvironment?.map((x: any) => {
              return <MenuItem value={x.name} key={x.name}>{x.name}</MenuItem>
            })}
            <MenuItem value={props.customEnvironment.name}>
              Custom
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <p className="test66">Application address:</p>
        <Box
          contentEditable={props.customEnvironment === props.ocapiEnvironment}
          className="sideContentTextArea">
          {props?.ocapiEnvironment?.address}
        </Box>
        {
          props.customEnvironment === props.ocapiEnvironment &&
          <div>
            <p className="test66">Token:</p>
            {props.ocapiEnvironment?.application.map((x: any) => {
              return (
                <Box
                  contentEditable={true}
                  className="sideContentTextArea"
                  id="myTokenArea"
                >
                  {x.token}
                </Box>
              )
            })}
            <Button
              onClick={handleConfirm}
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              className="sideContent"
            >
              Confirm
            </Button>
          </div>
        }
      </div>
    </div>
  );
}
