import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ValuesContainer from './ValuesContainer';


export default function ChildContainer(props: any) {
  return (
    <Accordion className="insurance"
      defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="insuranceTypography">
            {props.child.label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <ValuesContainer request={props.request} refresh={props.refresh} setRequest={props.setRequest} items={props.child.items}></ValuesContainer>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
