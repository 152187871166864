import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Hausnummer(props: any) {
  const [hausnummer, setHausnummer] = React.useState("");
  const [counter, setCounter] = React.useState(0);

  const handleChange = (e: { target: { value: string; }; }) => {
    const wordRes = (e.target.value);

    setHausnummer(wordRes);
    props.setHouseNumber(wordRes);
  };

  //React.useEffect(() => {
  //  if (counter > 0) {
  //    setHausnummer(props.eccResponse.address.houseNumber);
  //  } else {
  //    setCounter(1);
  //  }
    
  //}, [props.eccResponse]);

  if (props.clearTxt) {
    if (hausnummer !== "") {
      setHausnummer("")
      props.setHouseNumber("");
    }
    props.setClearTxt(false)
    return (
      <Box component="form">
        <TextField
          className="ECC-Input"
          label="Hausnummer"
        />
      </Box>
    )
  } else {
    return (
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <TextField
          className="ECC-Input"
          id="standard-basic"
          label="Hausnummer"
          variant="standard"
          value={hausnummer}
          onChange={handleChange}
        />
      </Box>
    )
  }
}