/* eslint-disable jsx-a11y/iframe-has-title */
import axios from "axios";
import React, { useEffect, useRef, useState } from 'react';
import { SideContent } from "./SideContent";

export function Test() {
  const [config, setConfig] = useState({});
  useEffect(() => {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      }
    });
    axiosInstance.get("/Config.json")
      .then((res) => {
        setConfig((res.data));
      }
      )
  }, [])
  const [refresh, setRefresh] = React.useState(0)
  const [ocapiEnvironment, setOcapiEnvironment] = useState<any>({
    "name": "Test",
    "address": "https://test.ocapi.api.mercedes-benz.com/v3/at/calculations",
    "application": [
      {
        "key": "57d1a0d1-8e8f-46e2-b450-a36ffb83bc5d",
        "name": "DCP Marketplace",
        "label": "VDZ"
      }
    ]
  }
  )
  const [application, setApplication] = useState<any>()
  const [vehicle, setVehicle] = React.useState<any>("")
  const [language, setLanguage] = useState(
    {
      "abbreviation": "en_AT",
      "language": "english"
    },)
  const [request, setRequest] = useState({})
  const iRef = useRef<HTMLIFrameElement | null>(null);
  const [loaded, setLoaded] = useState(false);
  let src = "/ARC_Widget?URL=" + encodeURIComponent(ocapiEnvironment?.address) + "&debug=true";
  if (application?.key !== "") {
    src += "&ApiKey=" + application?.key
  }
  else {
    src += "&Token=" + application?.token
  }
  //if (iRef?.current?.src != src && loaded)
  //  setLoaded(false); 

  if (loaded) {
    if (iRef?.current?.src !== src) {
      setTimeout(() => {
        iRef?.current?.contentWindow?.postMessage({ type: "ocapiData", payload: JSON.stringify(request) }, '*');
      }, 1000);
    }
    else {
      iRef?.current?.contentWindow?.postMessage({ type: "ocapiData", payload: JSON.stringify(request) }, '*');
    }
  }

  const handleLoad = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }

  return (
    <table>
      <td className="sideArea">
        <tr className="sideArea">
          <SideContent
            vehicle={vehicle}
            setVehicle={setVehicle}
            setRefresh={setRefresh}
            language={language}
            setLanguage={setLanguage}
            application={application}
            setApplication={setApplication}
            ocapiEnvironment={ocapiEnvironment}
            setOcapiEnvironment={setOcapiEnvironment}
            request={request}
            setRequest={setRequest}
            config={config}>
          </SideContent>
        </tr>
      </td>
      <td className="Input">
        <tr></tr>
        <tr className="Input">
          <iframe
            className="Input"
            src={src}
            ref={iRef}
            onLoad={handleLoad}>
          </iframe>
        </tr>
      </td>
    </table>
  );
}