import i18n from "i18next";
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import {initReactI18next} from "react-i18next";
import de from "./de.json";
import zodDe from './zod-de.json';

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === "development",
  fallbackLng: "de",
  interpolation: {
    escapeValue: false
  },
  resources: {
    de: {
      translation: de,
      zod: zodDe,
    }
  }
});

z.setErrorMap(zodI18nMap);
export default i18n;
