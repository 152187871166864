import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import axios from "axios";

export default function VehicleSelect(props: any) {

  const setVehicle = (v: any) => {
    props.setVehicle(v);
    console.log(v.request)
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      }
    });
    axiosInstance.get("/Requests/" + v.request)
      .then((res) => {
        console.log(res)
        props.setRequest((res.data))
      }
      )
  };

  let key = props.vehicle?.name
  if (props.config?.vehicleSelection?.indexOf(props.vehicle) < 0) {
    if (props.config?.vehicleSelection && props.config?.vehicleSelection.length !== 0) {
      setVehicle(props.config?.vehicleSelection[0])
      key = props.config?.vehicleSelection[0].name
    }
  }
  const handleChange = (event: SelectChangeEvent) => {
    const i = props.config?.vehicleSelection?.findIndex((x: any) => x.name === event.target.value)
    setVehicle(props.config?.vehicleSelection[i]);
  };

  return (
    <FormControl className="sideContent" variant="filled">
      <InputLabel id="demo-simple-select-label">Vehicle</InputLabel>
      <Select
        value={key ?? ""}
        onChange={handleChange}
      > 
        {props.config.vehicleSelection?.map((x: any) => {
          return <MenuItem value={x.name} key={x.name}>{x.name}</MenuItem>
        })}
      </Select>
    </FormControl>
  )
}